import { render, staticRenderFns } from "./broker_classify_leve2.vue?vue&type=template&id=16aa61cb&scoped=true&"
import script from "./broker_classify_leve2.vue?vue&type=script&lang=js&"
export * from "./broker_classify_leve2.vue?vue&type=script&lang=js&"
import style0 from "./broker_classify_leve2.vue?vue&type=style&index=0&id=16aa61cb&prod&lang=less&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.10.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16aa61cb",
  null
  
)

export default component.exports